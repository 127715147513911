const Animal = (props) => {
  const { dinosaur, turret, isRate } = props;
  const progress = Number(turret?.boss_hp) / Number(dinosaur?.hit_points);
  const award1 = require("../../../../assets/images/gif/mao.gif");
  return (
    <div className="animal" style={props.style}>
      {/* {dinosaur.category === 1 && (
        <div className="animal_container">
          <div
            className="animal_container_progress"
            style={{
              width: `${progress * 100}%`,
            }}
          />
        </div>
      )} */}
      {dinosaur?.gif_path && (
        <img
          style={{ transform: isRate ? "rotateY(180deg)" : "rotateY(0deg)" }}
          // src={award1}
          src={dinosaur?.gif_path}
          alt=""
        />
      )}
    </div>
  );
};

export default Animal;
